/* eslint-disable react-hooks/rules-of-hooks */
import { Content, asHTML, asLink, asText } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import clsx from 'clsx';
import { Button } from 'ui/components/Button';
import { KeyHighlight } from 'ui/components/KeyHighlight/KeyHighlight';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { ProcessSectionNew } from 'ui/sections/ProcessSectionNew';
import { Section, SectionBackground } from 'ui/sections/section';
import { getFileNameWithoutExtension } from 'ui/utils/extractfilenameWithoutExtension';
import { slugify } from 'ui/utils/slugify';
import { VideoInternal } from 'ui/components/VideoInternal';
import { extractVideoId } from 'ui/components/developer/Video/video.helper';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import imageMapping from "./image.mapping.json";
/**
 * Props for `ContentFold`.
 */
export type ContentFoldProps = SliceComponentProps<Content.ContentFoldSlice>;

/**
 * Component for "ContentFold" Slices.
 */
const ContentFold = ({ slice }: ContentFoldProps): JSX.Element => {
  const { primary, slice_type, variation } = slice;
  const { background, image } = primary;


  if (variation === 'default') {
    const { top_spacing, bottom_spacing, tag, title, description, description_font_size, image_placement } =
      slice.primary;
      const fileName = getFileNameWithoutExtension(image?.url);
    return (
      <Section
        className="ContentFold"
        data-slice-type={slice_type}
        data-slice-variation={variation}
        bg={background as SectionBackground}
        botPad={bottom_spacing}
        topPad={top_spacing}
      >
        <div className="[ grid grid-cols-1 gap-8 justify-center items-center lg:grid-cols-2 lg:gap-32 ]">
          <div className={clsx('[ w-full ][ mb-12 ][ text-center ]', {
            'lg:order-1': image_placement === 'right'
          })}>
            {imageMapping[fileName] ? <Image src={imageMapping[fileName]} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ]" width={516.259} height={406.887} alt='searchDev' /> : <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ]" />}
          </div>
          <div>
            {tag && <span className="[ block ][ text-12 lg:text-14 font-bold ]">{tag}</span>}
            {title && <h3 className="[ text-30 md:text-46 leading-tight tracking-tight ][ mb-4 lg:mt-2 ]">{title}</h3>}
            {asText(description) && (
              <div
                className={clsx("[ mb-8 ]", {
                  'text-16': description_font_size === 'md',
                  'text-20': description_font_size === 'lg',
                })}
              >
                <PrismicRichText field={description} />
              </div>
            )}
            {slice.items && slice.items.length > 0 && (
              <div className="[ flex gap-4 ]">
                {slice.items.map((button, index) => {
                  const link = asLink(button.button_link);
                  const id = slugify(link || '');
                  return (
                    <Button
                      key={link}
                      field={button.button_link}
                      outline={button.outlined}
                      arrowIcon
                      id={`content-fold-${id}`}
                    >
                      {button.button_text}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Section>
    );
  }

  if (variation === 'steps') {
    const { top_spacing, bottom_spacing, title, cta_text, cta_url, steps } = primary;

    const boxes = ((steps as any)?.data?.steps || []).map(item => ({
      title: item.title,
      text: item.description,
    }));
    return (
      <ProcessSectionNew
        process={{
          boxes,
          button: {
            href: asLink(cta_url),
            text: cta_text,
          },
          sectionTitle: title,
        }}
        image={<PrismicNextImage field={image} />}
        sectionProps={{ bg: background, topPad: top_spacing, botPad: bottom_spacing }}
      />
    );
  }

  if (variation === 'keyHighlights') {
    const { top_spacing, bottom_spacing, section_title, section_description, sub_heading, image_placement, content } =
      slice.primary;
    return (
      <Section
        className="ContentFold-KeyHighlights"
        bg={background as SectionBackground}
        title={section_title}
        subTitle={section_description}
        data-slice-type={slice_type}
        data-slice-variation={variation}
        headlineMargin="none"
        botPad={bottom_spacing}
        topPad={top_spacing}
      >
        <div className="[ grid grid-cols-1 gap-8 justify-center items-center lg:grid-cols-2 lg:gap-32 ]">
          <div className={clsx('[ w-4/5 md:w-full ][ mx-auto ][ text-center ]', {
            'lg:order-1': image_placement === 'right'
          })}>
            <PrismicNextImage field={image} className="[ w-full max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ object-contain ]" />
          </div>
          <div>
            {!!asHTML(sub_heading) && <PrismicRichText field={sub_heading} />}
            {!!asHTML(content) && (
              <div className="[ text-20 ][ mb-6 lg:mb-14 ]">
                <PrismicRichText field={content} />
              </div>
            )}
            {slice.items && slice.items.length > 0 && (
              <div className="[ mb-6 lg:mb-14 ]">
                {slice.items.map((highlight, index) => {
                  const { key_title, key_description, icon } = highlight;
                  return <KeyHighlight image={icon} title={key_title} text={key_description} key={index} />;
                })}
              </div>
            )}
            <div className="[ flex gap-4 ]">
              {slice.primary.primary_button_text && (
                <Button id={slice.primary.primary_button_text} field={slice.primary.primary_button_link} arrowIcon>
                  {slice.primary.primary_button_text}
                </Button>
              )}
              {slice.primary.secondary_button_text && (
                <Button
                  id={slice.primary.secondary_button_text}
                  outline={true}
                  field={slice.primary.secondary_button_link}
                  arrowIcon
                >
                  {slice.primary.secondary_button_text}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Section>
    );
  }

  if (variation === 'dynamic') {
    const {
      eyebrow,
      eye_brow_text_color,
      heading,
      heading_text_color,
      description,
      description_text_color,
      image_placement,
      background,
      thumbnail,
      youtube_video_id,
      media_type,
      full_bleed_background_image,
      list_marker_bg_color,
      padding,
    } = slice.primary;
    // For now having full bleed means light content color
    const hasFullBleed = full_bleed_background_image.url;
    return (
      <section
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{
          ...(full_bleed_background_image.url ? { backgroundImage: `url(${full_bleed_background_image.url})` } : {}),
        }}
        className={clsx('ContenFold-Dynamic wide-container [ bg-no-repeat bg-center bg-cover ]', {
          'bg-blue-50': background === 'blue',
          'bg-gray-97': background === 'light',
          'bg-blue-95': background === 'light_blue',
          'bg-gray-0': background === 'dark'
        })}
      >
        <div
          className={clsx('[ grid grid-cols-1 gap-8 justify-center items-center lg:grid-cols-2 lg:gap-32 ]', {
            '[ py-8 px-5 md:py-12 md:px-16 ]': padding === 'xs',
            '[ py-12 px-5 md:p-16 lg:py-20 ]': padding === 'sm',
            '[ py-16 px-5 md:py-20 md:px-16 lg:py-28 ]': padding === 'm',
            '[ py-20 px-5 md:py-24 md:px-16 lg:py-32 ]': padding === 'l',
            '[ py-24 px-5 md:py-28 md:px-16 lg:py-40 ]': padding === 'xl',
            '[ py-28 px-5 md:py-36 md:px-16 lg:py-48 ]': padding === 'xxl',
            '[ py-36 px-5 md:py-40 md:px-16 lg:py-56 ]': padding === 'xxxl'
          })}
        >
          <div className={clsx('[ hidden lg:block ]', {
            'lg:order-1' : image_placement === 'right'
          })}>
            {media_type === 'Video' ? (
              <VideoInternal
                data={{
                  video: { id: extractVideoId(youtube_video_id) },
                  thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />,
                }}
                playIcon={'/img/icons/icon-play-button.svg'}
                className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
              />
            ) : (
              <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" />
            )}
          </div>
          <div>
            <div className="[ lg:hidden ]">
              {full_bleed_background_image.url ? (
                <PrismicNextImage field={image} />
              ) : (
                <>
                  {media_type === 'Video' ? (
                    <VideoInternal
                      data={{
                        video: { id: extractVideoId(youtube_video_id) },
                        thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />,
                      }}
                      playIcon={'/img/icons/icon-play-button.svg'}
                      className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
                    />
                  ) : (
                    <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" />
                  )}
                </>
              )}
            </div>
            {eyebrow && 
              <span className={clsx('[ text-16 font-medium ][ block ][ mb-4 ]', {
                'text-gray-0': eye_brow_text_color === 'primary_black',
                'text-white': eye_brow_text_color === 'primary_white',
                'text-gray-60': eye_brow_text_color === 'secondary_white',
                'text-gray-40': eye_brow_text_color === 'secondary_black'
              })}>{eyebrow}</span>
            }
            {heading && 
              <h2 className={clsx('[ text-30 md:text-46 font-medium leading-tight tracking-tight text-balance ][ mb-8 ]', {
                'text-gray-0': heading_text_color === 'primary_black',
                'text-white': heading_text_color === 'primary_white',
                'text-gray-60': heading_text_color === 'secondary_white',
                'text-gray-40': heading_text_color === 'secondary_black'
              })}>{heading}</h2>
            }
            {asText(description) && (
              <span
                className={clsx('[ text-16 ]', {
                  'text-gray-0': description_text_color === 'primary_black',
                  'text-white': description_text_color === 'primary_white',
                  'text-gray-60': description_text_color === 'secondary_white',
                  'text-gray-40': description_text_color === 'secondary_black',
                  '[&_li]:before:bg-white [&_li]:before:text-gray-0' : list_marker_bg_color === 'light',
                  '[&_li]:before:bg-blue-95 [&_li]:before:text-blue-50' : list_marker_bg_color === 'light_blue',
                  '[&_li]:before:bg-gray-0 [&_li]:before:text-white' : list_marker_bg_color === 'dark',
                })}
              >
                <PrismicRichText field={description} applyStyles={false} />
              </span>
            )}
            {slice.items && slice.items.length > 0 && (
              <div className="[ flex gap-1 ]">
                {slice.items.map((button, index) => {
                  const link = asLink(button.button_link);
                  const id = slugify(link || '');
                  return (
                    <Button
                      key={link}
                      field={button.button_link}
                      arrowIcon={button.button_with_arrow}
                      id={`content-fold-${id}`}
                      className={clsx('[ rounded-lg ][ w-full md:w-auto ]', {
                        'bg-gray-0 hover:bg-gray-10 border-gray-0': button.color_cta_type === 'Primary',
                        'bg-white hover:bg-gray-90 border-gray-30 text-gray-0': button.color_cta_type === 'Secondary'
                      })}
                      target={button.target}
                      outline={button.color_cta_type === 'Secondary'}
                    >
                      {button.button_text}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
  if (variation === 'dynamic1Fr1FrMin768Px') {
    const {
      eyebrow,
      eye_brow_text_color,
      heading,
      heading_text_color,
      description,
      description_text_color,
      image_placement,
      background,
      thumbnail,
      youtube_video_id,
      media_type,
      full_bleed_background_image,
      list_marker_bg_color,
      padding,
    } = slice.primary;
    // For now having full bleed means light content color
    const hasFullBleed = full_bleed_background_image.url;
    const [bg, setBg] = useState();
    useEffect(() => {
      window.addEventListener('backgroundColorChange', (event: any) => {
        setBg(event.detail.color);
      });
      () => {
        window.removeEventListener('backgroundColorChange', () => {});
      };
    }, []);
    return (
      <section
        data-slice-type={slice_type}
        data-slice-variation={variation}
        className={clsx('ContentFold-Dynamic1fr wide-container color-changing-section', {
          'bg-blue-50': background === 'blue',
          'bg-gray-97': background === 'light', 
          'bg-blue-95': background === 'light_blue',
          'bg-gray-0': background === 'dark'
        })}
        data-background={background}
      >
        <div className="[ py-10 px-5 md:px-16 lg:py-24 ]">
          <div
            className={clsx('[ grid grid-cols-1 items-center gap-4 md:grid-cols-12 lg:gap-8 ]',{
              '[ bg-no-repeat bg-center bg-cover ][ rounded-2xl ][ p-11 lg:p-16 ]': full_bleed_background_image.url,
            })}
            style={{
              ...(full_bleed_background_image.url
                ? { backgroundImage: `url(${full_bleed_background_image.url})` }
                : {}),
            }}
          >
            <div className={clsx('[ hidden md:block ][ relative w-4/5 ][ mx-auto ][ md:col-span-5 ]', {
              'lg:order-1' : image_placement === 'right'
            })}>
              {media_type === 'Video' ? (
                <VideoInternal
                  data={{
                    video: { id: extractVideoId(youtube_video_id) },
                    thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />,
                  }}
                  playIcon={'/img/icons/icon-play-button.svg'}
                  className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
                />
              ) : (
                <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" />
              )}
            </div>
            <div className="[ md:col-span-7 ]">
              <div className="[ md:hidden ][ w-4/5 relative ][ mx-auto ]">
                {full_bleed_background_image.url ? (
                  <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" />
                ) : (
                  <>
                    {media_type === 'Video' ? (
                      <VideoInternal
                        data={{
                          video: { id: extractVideoId(youtube_video_id) },
                          thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />,
                        }}
                        playIcon={'/img/icons/icon-play-button.svg'}
                        className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
                      />
                    ) : (
                      <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" />
                    )}
                  </>
                )}
              </div>
              {eyebrow && 
                <span className={clsx('[ text-16 font-medium ][ block ][ mb-4 ]', {
                  'text-gray-0': eye_brow_text_color === 'primary_black',
                  'text-white': eye_brow_text_color === 'primary_white',
                  'text-gray-60': eye_brow_text_color === 'secondary_white',
                  'text-gray-40': eye_brow_text_color === 'secondary_black'
                })}>{eyebrow}</span>
              }
              {heading && 
                <h2 className={clsx('[ text-30 md:text-46 font-medium leading-tight tracking-tight text-balance ][ mb-8 ]', {
                  'text-gray-0': heading_text_color === 'primary_black',
                  'text-white': heading_text_color === 'primary_white',
                  'text-gray-60': heading_text_color === 'secondary_white',
                  'text-gray-40': heading_text_color === 'secondary_black'
                })}>{heading}</h2>
              }
              {asText(description) && (
                <span
                  className={clsx('[ text-16 ]', {
                    'text-gray-0': description_text_color === 'primary_black',
                    'text-white': description_text_color === 'primary_white',
                    'text-gray-60': description_text_color === 'secondary_white',
                    'text-gray-40': description_text_color === 'secondary_black',
                    '[&_li]:before:bg-white [&_li]:before:text-gray-0' : list_marker_bg_color === 'light',
                    '[&_li]:before:bg-blue-95 [&_li]:before:text-blue-50' : list_marker_bg_color === 'light_blue',
                    '[&_li]:before:bg-gray-0 [&_li]:before:text-white' : list_marker_bg_color === 'dark',
                  })}
                >
                  <PrismicRichText field={description} applyStyles={false} />
                </span>
              )}
              {slice.items && slice.items.length > 0 && (
                <div className="[ flex gap-1 ]">
                  {slice.items.map((button, index) => {
                    const link = asLink(button.button_link);
                    const id = slugify(link || '');
                    return (
                      <Button
                        key={link}
                        field={button.button_link}
                        arrowIcon={button.button_with_arrow}
                        id={`content-fold-${id}`}
                        className={clsx('[ rounded-lg ][ w-full md:w-auto ]', {
                          'bg-gray-0 hover:bg-gray-10 border-gray-0': button.color_cta_type === 'Primary',
                          'bg-white hover:bg-gray-90 border-gray-30 text-gray-0': button.color_cta_type === 'Secondary',
                          'bg-blue-50 hover:bg-blue-30 border-blue-50 hover:border-blue-30 text-white': button.color_cta_type === 'hero',
                        })}
                        target={button.target}
                        outline={button.color_cta_type === 'Secondary'}
                      >
                        {button.button_text}
                      </Button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default ContentFold;
