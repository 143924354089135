export const ProcessImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      viewBox="0 0 738.9 346.37"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="110.76"
          x2="461.61"
          y1="-241.72"
          y2="-241.72"
          gradientTransform="matrix(1 0 0 -1 0 -68.78)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2b53bd"></stop>
          <stop offset="1" stopColor="#cf56fa"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="183.49"
          x2="611.86"
          y1="-395.15"
          y2="-395.15"
          gradientTransform="matrix(1 0 0 -1 0 -68.78)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="245.59"
          x2="292.38"
          y1="-163.67"
          y2="-163.67"
          gradientTransform="matrix(1 0 0 -1 0 -68.78)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#cf56fa"></stop>
          <stop offset="1" stopColor="#ffc122"></stop>
        </linearGradient>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M363.23 71.07l-47.46-22.3v33.65a.61.61 0 00.35.55l47.11 22.13s-.07-34.11 0-34z"
          ></path>
        </clipPath>
        <linearGradient
          id="linear-gradient-4"
          x1="250.3"
          x2="296.55"
          y1="-165.48"
          y2="-165.48"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="592.1"
          x2="725.31"
          y1="379.02"
          y2="379.02"
          gradientTransform="matrix(.96 -.29 -.29 -.96 90.72 746.85)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff2049"></stop>
          <stop offset="1" stopColor="#ffc122"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="551.31"
          x2="738.9"
          y1="-341.42"
          y2="-341.42"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="257.3"
          x2="312.41"
          y1="214.42"
          y2="214.42"
          gradientTransform="matrix(.84 -.54 -.54 -.84 -85.75 372.3)"
          xlinkHref="#linear-gradient-5"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="29.77"
          x2="229.2"
          y1="-162.64"
          y2="-162.64"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
      </defs>
      <path fill="#1154b2" d="M192.96 194.88H461.61V282.01H192.96z"></path>
      <path
        fill="url(#linear-gradient)"
        d="M110.76 150.99L376.82 150.99 461.61 194.88 192.96 194.88 110.76 150.99z"
      ></path>
      <path
        fill="url(#linear-gradient-2)"
        d="M136.72 306.37L185.09 306.37 550.14 306.37 617.7 346.37 201.69 346.37 136.72 306.37z"
        opacity="0.3"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        fill="#00407e"
        d="M192.96 282.01L110.76 231.66 110.76 150.99 192.96 194.88 192.96 282.01z"
      ></path>
      <path
        fill="#1154b2"
        d="M244.49 134.3l-10.86-43.85s-2.1-6.58 3.15-10 18.88-4.18 18.88-4.18l14.92 57-26.08 1z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M245.58 86.46a.5.5 0 01-.48-.38 21.52 21.52 0 00-1.2-3.92.49.49 0 11.84-.5 22.39 22.39 0 011.32 4.21.5.5 0 01-.37.59h-.11zM330.86 92.75h4.33v72.14a2.17 2.17 0 01-2.17 2.17 2.17 2.17 0 01-2.17-2.17V92.75z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M330.86 92.75v5.68a8.5 8.5 0 004.3 4.67V92.75z"
      ></path>
      <ellipse
        cx="270.54"
        cy="176.8"
        fill="#1e1e1e"
        opacity="0.15"
        rx="39.36"
        ry="10.63"
        style={{ isolation: 'isolate' }}
      ></ellipse>
      <ellipse
        cx="248.73"
        cy="167.74"
        fill="#1e1e1e"
        rx="3.43"
        ry="3.42"
      ></ellipse>
      <path
        fill="#1e1e1e"
        d="M266.77 164.32h-18v4.29h15.63s4.54-.61 2.37-4.29z"
      ></path>
      <ellipse
        cx="287.64"
        cy="167.74"
        fill="#1e1e1e"
        rx="3.43"
        ry="3.42"
      ></ellipse>
      <path
        fill="#1e1e1e"
        d="M269.6 164.32h18v4.29H272s-4.57-.61-2.4-4.29z"
      ></path>
      <path
        fill="#ff803b"
        d="M316.56 146.35l2.5 5.15c.51 1 1.22 1.61 2.28 1.31s2.45-.7 4.31-1.29c3.59-1.14 6.61-2 8.12-.56a1.37 1.37 0 01.2 1.74c-.39.59-6.73 5.06-6.73 5.06l-5.87 4.09c-2.8 1.77-4.89 3.12-6.25.74-.75-1.3-.61-3.28-1.54-5s-4-6.79-4-6.79M290.67 156.32l-1.82 6.41a1.9 1.9 0 001.13 2.36c1 .38 2.49.82 4.33 1.46 3.56 1.24 6.49 2.36 6.84 4.41a1.37 1.37 0 01-.89 1.51c-.67.24-8.43 0-8.43 0l-7.16-.25c-3.3-.26-5.78-.43-5.44-3.14.19-1.49 1.49-3 1.79-4.94s1.65-8.69 1.65-8.69"
      ></path>
      <path
        fill="#cf56fa"
        d="M289.68 160.12c-2.62.57-7.83-.4-7.83-.4-.34 1.77-.69 3.6-.82 4.43-.3 1.95-1.6 3.45-1.79 4.94-.34 2.71 2.14 2.88 5.44 3.14l7.16.25s7.76.23 8.43 0a1.37 1.37 0 00.89-1.51c-.34-2.05-3.28-3.17-6.84-4.41-1.84-.64-3.24-1.17-4.23-1.55s-1.22-1.33-1-2.42l.64-2.46z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M253 114.81l3.83 17.65 28.29-.27-2 9.48s-1.56 8.59-2.08 14.27a.55.55 0 00.36.6c1.22.39 4.94 1.29 11 .42a.53.53 0 00.43-.37c.78-2.45 5.86-18.4 6.78-21.62l8.69 15.94a.36.36 0 00.4.18c1-.23 4.4-1.27 9-5a.36.36 0 00.12-.38c-.77-2.68-8.24-28.6-11.12-34.86-3.08-6.69-6.25-15.1-33.56-5.62l-20 9.58z"
      ></path>
      <path
        fill="#8584f5"
        d="M270.57 133a.5.5 0 01-.48-.37.49.49 0 01.36-.59c.06 0 6-1.55 15.69-10.65 8.68-8.11 13.9-6.36 14.12-6.28a.49.49 0 11-.33.93s-4.93-1.57-13.12 6.08c-10 9.31-15.88 10.82-16.13 10.88h-.11z"
      ></path>
      <path
        fill="#8584f5"
        d="M299.49 135.67a.4.4 0 01-.13 0 .49.49 0 01-.34-.6c0-.05 1.4-5.05 2.44-9.73a6.94 6.94 0 00-.82-5.68c-1.83-2.6-5.45-3.09-5.49-3.09a.49.49 0 01-.43-.55.49.49 0 01.55-.43c.17 0 4.08.54 6.16 3.49a7.87 7.87 0 011 6.46c-1 4.72-2.44 9.74-2.45 9.79a.49.49 0 01-.49.34zM285 132.54h-.09a.49.49 0 01-.39-.57l.71-3.76a.49.49 0 111 .18l-.71 3.76a.49.49 0 01-.48.4z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M301.32 172.71l-20.85-.44a.8.8 0 01-.79-.82.8.8 0 01.83-.79l20.85.44a.8.8 0 01.79.82.8.8 0 01-.83.79z"
      ></path>
      <path
        fill="#cf56fa"
        d="M334 152.69a1.37 1.37 0 00-.2-1.74c-1.51-1.43-4.53-.58-8.12.56-1.86.59-3.25.82-4.26 1.1s-1.73-.2-2.25-1.18l-1.17-2.11c-1.81 2-5.83 4-6.65 4.36a147.4 147.4 0 012.23 3.87c.93 1.74.79 3.72 1.54 5 1.36 2.37 3.45 1 6.25-.74l5.87-4.09s6.34-4.46 6.73-5.06z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M335.3 153l-17.11 12.2a.87.87 0 01-1.19-.2.87.87 0 01.2-1.21l17.11-12.22a.87.87 0 011.21.2.87.87 0 01-.2 1.21zM319.68 155.36h-.09a.49.49 0 01-.39-.57 3.77 3.77 0 012-2.61.49.49 0 01.41.89 2.75 2.75 0 00-1.44 1.89.48.48 0 01-.48.4z"
      ></path>
      <path
        fill="#d3d3d3"
        d="M383.65 116.15H388v67.94a2.17 2.17 0 01-2.17 2.17 2.17 2.17 0 01-2.17-2.17v-67.94zM312 116.15h4.33v67.94a2.17 2.17 0 01-2.17 2.17 2.17 2.17 0 01-2.17-2.17v-67.94z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M383.65 116.15v5.68a8.5 8.5 0 004.3 4.67v-10.35zM312 116.15v5.68a8.5 8.5 0 004.3 4.67v-10.35z"
      ></path>
      <path
        fill="#d3d3d3"
        d="M266.98 92.5L340.1 92.45 388.63 115.87 315.36 115.87 266.98 92.5z"
      ></path>
      <path
        fill="#979797"
        d="M266.98 95.44L315.36 118.82 388.63 118.82 388.63 115.87 315.36 115.87 266.98 92.5 266.98 95.44z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M315.36 119.24a.43.43 0 01-.43-.43v-2.94a.43.43 0 01.86 0v2.94a.43.43 0 01-.43.43z"
      ></path>
      <path
        fill="#ffc500"
        d="M302.42 91.54a7.09 7.09 0 013.75-.82c2.54 0 4 1 4.22 2.18s-.47 2.22-3.51 2.69c-3.67.56-5.47-.54-5.58-1.91a2.48 2.48 0 011.13-2.14z"
      ></path>
      <path
        fill="#ff803b"
        d="M291.63 97.57l6.35-.35a20 20 0 006.68-.37l3.77-.65a.87.87 0 00.73-.83.89.89 0 00-.93-.92l-4.47.22a.81.81 0 01-.63-.24 1.39 1.39 0 01-.3-1.32c.28-1.19 3.45-1.9 6.77-1.89a1 1 0 001.06-1 .66.66 0 00-.38-.49s.14-.77-.42-.87-3.08-.07-5.56-.1a7.37 7.37 0 00-3.1.61 5.36 5.36 0 00-1.21.79 12.4 12.4 0 01-3.78 2.15 2.88 2.88 0 01-.87.14h-4.91a8.73 8.73 0 00-.24 4.14c.35 1.64 1.43 1 1.43 1z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M303.79 90.26a.49.49 0 01-.49-.49.5.5 0 01.49-.5l6.5-.07a.49.49 0 01.49.49.5.5 0 01-.49.5l-6.5.07z"
      ></path>
      <path
        fill="url(#linear-gradient-3)"
        d="M291.59 100.47a8.15 8.15 0 00-.46-7.89.5.5 0 00-.41-.19l-14.79.14s0 .84-2.38-6-4.62-9.25-6.57-10.46c-4.34-2.69-13.63-1.72-18 1.92a10.46 10.46 0 00-3.41 8l7.41 29s8 2.08 16.08-4.76"
      ></path>
      <path
        fill="#979797"
        d="M319.81 109.88l16.75-4.77v2a.32.32 0 01-.26.31z"
      ></path>
      <path
        fill="#ff803b"
        d="M253.34 76.85a10.21 10.21 0 007.71 1 2.25 2.25 0 001.58-2.25 30.17 30.17 0 01.38-7s-3.41-9.11-8.12-3.9z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M259.52 67.54s1.82 3.58 3.08 4a24.22 24.22 0 01.7-3.86z"
      ></path>
      <path
        fill="#ff803b"
        d="M259.65 68s3.34 1.94 5.14 1.86c2.22-.1 5.37-.38 5.58-4.42.22-4.35.56-9 .52-10.69-.05-1.87-2.05-5.8-7.27-6.08s-9.23 2-10.21 4.83-.37 9 1.49 11.25a11.69 11.69 0 004.75 3.25z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M272.28 98.88a.48.48 0 01-.48-.4c0-.12-2.31-11.94-3.3-16.48a.49.49 0 011-.2c1 4.55 3.28 16.38 3.3 16.5a.5.5 0 01-.39.58z"
      ></path>
      <path
        fill="#f3f3f3"
        d="M314.35 93.79L336.51 105a.11.11 0 010 .2l-16.56 4.64a.5.5 0 01-.38 0l-22-11.14a.11.11 0 010-.2l16.72-4.69h.07z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M276.55 94.93a.48.48 0 01-.45-.52 2.52 2.52 0 00-.51-1.57.49.49 0 01.06-.69.48.48 0 01.69.06 3.42 3.42 0 01.74 2.28.49.49 0 01-.49.46z"
      ></path>
      <path
        fill="#d3d3d3"
        d="M266.43 141.67h4.28v23.51a2.14 2.14 0 01-2.14 2.14 2.14 2.14 0 01-2.14-2.14v-23.51z"
      ></path>
      <ellipse
        cx="244.02"
        cy="178.64"
        fill="#1e1e1e"
        rx="3.43"
        ry="3.42"
      ></ellipse>
      <path
        fill="#1e1e1e"
        d="M266.43 145.94a21.33 21.33 0 014.28-2.22v-2.06h-4.28v4.27z"
      ></path>
      <path
        fill="#d3d3d3"
        d="M266.43 165.19l-23.79 10.32a3.42 3.42 0 002.29 6.43 3.33 3.33 0 01-.45-1.75 3.45 3.45 0 012.54-3.3l22.19-9.66"
      ></path>
      <ellipse
        cx="293.18"
        cy="178.64"
        fill="#1e1e1e"
        rx="3.43"
        ry="3.42"
      ></ellipse>
      <path
        fill="#d3d3d3"
        d="M270.78 165.19l23.79 10.32a3.42 3.42 0 01-2.29 6.43 3.33 3.33 0 00.45-1.75 3.45 3.45 0 00-2.54-3.3L268 167.23"
      ></path>
      <path
        fill="#1154b2"
        d="M244.28 132.19h41.59a4.83 4.83 0 014.83 4.81 4.83 4.83 0 01-4.83 4.83H254a9.67 9.67 0 01-9.67-9.67z"
      ></path>
      <path
        fill="#00407e"
        d="M247.1 122.71h18.74a4.87 4.87 0 014.88 4.88 4.87 4.87 0 01-4.88 4.88H247.1a4.87 4.87 0 01-4.88-4.88 4.87 4.87 0 014.88-4.88z"
      ></path>
      <path
        fill="#979797"
        d="M270.72 127.59a5 5 0 00-.22-1.46c-1.09 1.55-5.53 1.46-5.53 1.46h-22.75a4.89 4.89 0 004.89 4.88h18.71a4.88 4.88 0 004.89-4.88z"
        opacity="0.11"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        fill="#979797"
        d="M363.23 105.1l.84-.42a3 3 0 001.68-2.72V71.59a2.83 2.83 0 00-1.64-2.59l-44.48-20.9a3 3 0 00-2.66 0l-1.21.6"
      ></path>
      <path
        fill="#1e1e1e"
        d="M339.79 101.36s1.32.75 4.08-.71a4.72 4.72 0 002.45-4.31v-1.43h-4.28z"
      ></path>
      <path
        fill="#979797"
        d="M345.32 96.67c-.31 3.86-3.93 4.58-5.11 4.72a1.19 1.19 0 01-.67-.11l-9.31-4.4a.13.13 0 010-.24c1.25-.17 4.7-1 5-4.77l10.06 4.8z"
      ></path>
      <path
        fill="#f3f3f3"
        d="M363.23 71.07l-47.46-22.3v33.65a.61.61 0 00.35.55l47.11 22.13s-.07-34.11 0-34z"
      ></path>
      <g clipPath="url(#clip-path)" opacity="0.05">
        <path fill="none" d="M312.67 80.23L363.78 104.24"></path>
        <path
          fill="#1e1e1e"
          d="M363.78 109.65a5.33 5.33 0 01-2.29-.51l-51.11-24a5.4 5.4 0 014.6-9.78l51.11 24a5.41 5.41 0 01-2.3 10.3z"
        ></path>
      </g>
      <path
        fill="#fff"
        d="M340.85 93.28a.54.54 0 01-.21 0l-1.72-.83a.5.5 0 01-.23-.66.49.49 0 01.66-.23l1.72.83a.5.5 0 01.23.66.48.48 0 01-.44.28z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M363.23 71.49a.42.42 0 01-.38-.24.43.43 0 01.19-.57l1.95-1a.42.42 0 01.38.76l-1.95 1a.43.43 0 01-.19 0z"
      ></path>
      <path
        fill="#ff803b"
        d="M311.74 97.66c0-.89-1.22-.52-1.22-.52l-10.65 4.31a3.05 3.05 0 01-1 .21c-1 0-3.14.06-4.64 0 0 0-1.32.9-.51 2.95a3.29 3.29 0 002.38 2.25l5.84-.08a7.91 7.91 0 004.72-1.36l5.75-.76a.65.65 0 00.54-.6.63.63 0 00-.62-.65l-4.9-.08 6.6-1.88a.62.62 0 00.37-.88.62.62 0 00-.66-.32l-7.19 1.36 5.37-2.76a.62.62 0 00.19-.94.72.72 0 00-.69-.19l-.56.22"
      ></path>
      <path
        fill="url(#linear-gradient-4)"
        d="M253.27 94l12.17 13.11s2.87 3 4.89 3.21a79.28 79.28 0 009.65-.21c3.21-.19 13.59-.44 15.7-.68a.58.58 0 00.49-.41c.42-1.33 1.47-7.47-3.71-8.82a.83.83 0 00-.22 0l-17.65.93-13.91-15.74s-4-4.67-8.6-.91 1.19 9.52 1.19 9.52z"
      ></path>
      <path
        fill="#1e1e1e"
        d="M269.07 110.65a.32.32 0 01-.17 0c-1.57-.59-3.74-3.1-3.83-3.21s-8.7-9.06-12.17-12.3a.49.49 0 11.67-.71c3.5 3.26 12.14 12.27 12.23 12.36a12.93 12.93 0 003.46 2.95.49.49 0 01-.18 1zM275.31 101.24a2.17 2.17 0 01-1.6-.71c-.12-.15-11.26-13.38-15.83-16.64a.49.49 0 11.56-.8c4.67 3.32 15.54 16.24 16 16.79a1.09 1.09 0 00.9.37l16.08-.52a.5.5 0 110 1l-16.08.52h-.06zM304.21 101.48a.51.51 0 01-.45-.28.5.5 0 01.24-.65s4.25-2 7.42-3.3a.48.48 0 01.64.26.49.49 0 01-.26.64c-3.16 1.33-7.35 3.26-7.39 3.28a.54.54 0 01-.21 0zM305.31 102.52a.49.49 0 01-.15-1l2-.67a.49.49 0 01.31.93l-2 .67a.45.45 0 01-.16 0z"
      ></path>
      <path d="M266.58 47.74c-4.69-3.21-13.64.11-15.11 6.36-1.29 5.48.69 10.54 3.2 12.36l3.84-3.79.92.46a1 1 0 001-.48c.26-.55-.21-1.72-.9-3.23s-1-3.72.83-4.44 4.53.54 6.89-.07c1.92-.5 1.93-1.45 2.83-1.64.34-.07.69.32.87.94-.36-2.87-1.53-4.52-4.39-6.47z"></path>
      <path
        fill="#ff803b"
        d="M260.1 60a3.45 3.45 0 00-.63-1.2 1.8 1.8 0 00-2-.67c-1.68.53-1.09 5.15 1.79 5.57.68.1 1.18-.23 1.32-1.26a7.93 7.93 0 00-.48-2.44z"
      ></path>
      <path
        fill="url(#linear-gradient-5)"
        d="M681.2 188.34L553.52 226.25 546.55 202.9 674.23 164.99 681.2 188.34z"
      ></path>
      <path
        fill="#ff2d4f"
        d="M711.05 153.75L681.22 188.34 674.24 164.98 703.75 134.12 711.05 153.75z"
      ></path>
      <path
        fill="#ffc122"
        d="M546.55 202.9L575.47 175.6 703.75 134.12 674.24 164.98 546.55 202.9z"
      ></path>
      <path
        fill="url(#linear-gradient-6)"
        d="M565.49 269.44L738.9 269.44 722.64 275.84 551.31 275.84 565.49 269.44z"
        opacity="0.3"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        fill="url(#linear-gradient-7)"
        d="M76.12 46.21L29.77 75.9 0 29.68 46.35 0 76.12 46.21z"
      ></path>
      <path
        fill="#ff2d4f"
        d="M46.35 0L78.01 0 106.32 45.65 76.12 45.65 46.35 0z"
      ></path>
      <path
        fill="#e31b3f"
        d="M29.77 75.9L76.12 45.65 106.32 45.65 59.29 75.9 29.77 75.9z"
      ></path>
      <path
        fill="url(#linear-gradient-8)"
        d="M29.77 90.18L229.2 90.18 210.51 97.54 76.33 97.54 29.77 90.18z"
        opacity="0.3"
        style={{ isolation: 'isolate' }}
      ></path>
    </svg>
  );
};
