import { isValidElement } from 'react';
import { Button } from 'ui/components/Button';
import { ProcessBoxListNew } from 'ui/components/ProcessBoxNew';
import { Section } from 'ui/sections/section';
import { ProcessImage } from 'ui/svgs/process-section/ProcessImage';
import { constants } from 'ui/utils/constants';

const defaultProcess = {
  sectionTitle: 'How to become a Turing developer?',
  subTitle: 'Work with the best software companies in just 4 easy steps',
  boxes: [
    {
      title: (
        <span>
          Create your
          <br /> profile
        </span>
      ),
      text: 'Fill in your basic details - Name, location, skills, salary, & experience.',
    },
    {
      title: (
        <span>
          Take our tests
          <br /> and interviews
        </span>
      ),
      text: 'Solve questions and appear for technical interview.',
    },
    {
      title: (
        <span>
          Receive
          <br /> job offers
        </span>
      ),
      text: 'Get matched with the best US and Silicon Valley companies.',
    },
    {
      title: (
        <span>
          Start working
          <br /> on your dream job
        </span>
      ),
      text: 'Once you join Turing, you’ll never have to apply for another job.',
    },
  ],
  button: {
    text: 'Apply now',
  },
};

const defaultClientProcess = {
  sectionTitle: 'How to build your front-end development team with Turing?',
  boxes: [
    {
      title: (
        <span>
          Tell us the
          <br /> skills you need
        </span>
      ),
      text: "We'll schedule a call and understand your requirements.",
    },
    {
      title: (
        <span>
          We find the best
          <br /> talent for you
        </span>
      ),
      text: 'Get a list of pre-vetted candidates within days.',
    },
    {
      title: (
        <span>
          Schedule
          <br /> interviews
        </span>
      ),
      text: 'Meet and select the developers you like.',
    },
    {
      title: (
        <span>
          Begin
          <br /> your trial
        </span>
      ),
      text: 'Start building with a no-risk, 2-week trial period.',
    },
  ],
  button: {
    text: constants.CUSTOMER_CTA_TEXT,
    href: '/hire-developers',
  },
};
export const ProcessSectionNew = ({
  process: processProp,
  sectionProps = {},
  stretchButton = false,
  image = null,
  arrowIconButton = true,
  size = 'md',
  isClient = false,
  buttonText = '',
}) => {
  const process = processProp || (isClient ? defaultClientProcess : defaultProcess);

  const { href: buttonHref, text, ...buttonProperties } = process.button;

  const buttonTextWithDefault = buttonText || text;

  return (
    <Section
      subTitle={process.subTitle}
      textAlign="center"
      headlineMargin="xl"
      containerClass="max-w-screen-lg"
      {...sectionProps}
    >
      <div className="[ grid grid-cols-1 items-center md:grid-cols-12 md:gap-5 lg:gap-14 ]">
        <div className="[ md:col-span-5 lg:col-span-6 ]">
          <h3 className="[ text-28 font-medium ]">{process.sectionTitle}</h3>
          {process.subTitle && <h4>{process.subTitle}</h4>}
          <div className="[ hidden md:block ][ py-12 px-8 ]">{isValidElement(image) ? image : <ProcessImage />}</div>
        </div>
        <div className="[ md:col-span-7 lg:col-span-6 ]">
          {process.boxes && <ProcessBoxListNew list={process.boxes} size={size} />}
          <Button
            text={buttonTextWithDefault}
            arrowIcon={arrowIconButton}
            cta={buttonHref}
            className="[ mt-4 md:mt-16 ]"
            stretch={stretchButton}
            {...buttonProperties}
          />
        </div>
      </div>
    </Section>
  );
};
