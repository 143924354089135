import { FC } from 'react';
import type { ImageField } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';

interface KeyHighlightProps {
  image: ImageField;
  title?: string;
  text?: string;
}

export const KeyHighlight: FC<KeyHighlightProps> = ({ image, title, text }) => {
  return (
    <div className="[ flex ][ mb-6 md:mb-8 last:mb-0 ]">
      <div className="[ w-12 ][ mr-6 ][ flex-shrink-0 ]">
        <PrismicNextImage field={image} width={48} height={48} />
      </div>
      <div className="[ my-auto ][ text-14 md:text-16 lg:text-20 ]">
        {title && <h3>{title}</h3>}
        {text && <div>{text}</div>}
      </div>
    </div>
  );
};
