import clsx from 'clsx';

export const ProcessBoxNew = ({ index, title, text }) => {
  return (
    <div className="[ flex items-center gap-2 md:gap-4 ]">
      <span className="[ flex flex-shrink-0 items-center justify-center ][ w-10 h-10 ][ rounded-full border-2 border-solid border-blue-50 ][ text-blue-50 text-16 font-medium ][ mb-1.5 ]">{index + 1}</span>
      <div>
        <h3 className="[ mb-1 ]">{title}</h3>
        <p className="[ mb-0 ]">{text}</p>
      </div>
    </div>
  );
};
export const ProcessBoxListNew = ({ list, size }) => {
  return (
    <ol className={clsx('[ p-0 xl:mx-auto ][ space-y-5 ][ counter-reset[counter] list-none ]', {
      'grid grid-cols-4' : size === 'sm'
    })}>
      {list.map((box, i) => {
        return (
          <li className="[  ]" key={i}>
            <ProcessBoxNew index={i} {...box} />
          </li>
        );
      })}
    </ol>
  );
};
